<template>
  <br/>
  <tr>
    <td colspan="2">경력<em class="required">*</em>
      <hr/>
    </td>
  </tr>

  <tr>
    <td colspan="2">
      <div style="display: flex;">
        <div v-for="(item, index) in keyList" :key="index" style="width: 100%;">
          <input type="text" :value="item" disabled/>
        </div>
      </div>
    </td>
  </tr>


  <tr>
    <td colspan="2">
      <template v-for="(exp, index) in ARTIST_EXPERIENCES" :key="index">
        <div v-for="(experience, indexExpItem) in groupedExperience[exp.value]" :key="indexExpItem">
          <div style="display: flex; position: relative;">
            <div v-for="([key, value], idx) in Object.entries(experience)" :key="idx" style="width: 100%; margin-bottom: 4px;">
              <input v-if="key === 'type'" type="text" :placeholder="value" v-model="exp.label" disabled/>
              <CustomSelect v-else-if="key === 'year'" v-model="experience[key]" :options="selectYearRange" placeholder="연도"/>
              <CustomSelect v-else-if="key === 'status'" v-model="experience[key]" :options="ARTIST_EDUCATIONS_STATUS" placeholder="졸업 여부" />
              <input v-else type="text" :placeholder="value" v-model="experience[key]"/>
            </div>
            <button v-if="indexExpItem === 0" type="button" class="add-list" @click="addListItem(experience.type)">
              <img src="@/assets/images/icon/ico_add3.svg"/>
            </button>
            <button v-else type="button" class="add-list delete" @click="deleteListItem(experience.type, indexExpItem)">
              <img src="@/assets/images/icon/ico_delete.svg"/>
            </button>
          </div>
        </div>
      </template>
    </td>
  </tr>

  <tr>
    <td colspan="2">
      <hr/>
    </td>
  </tr>
</template>

<script setup>
import {computed, reactive, toRefs} from 'vue';
import {ARTIST_EDUCATIONS, ARTIST_EDUCATIONS_STATUS, ARTIST_EXPERIENCES} from "@/common/common-enum";
import {getSelectYearRange} from "@/common/common-function";
import CustomSelect from '@/components/ui/CustomSelect.vue';

const props = defineProps({
  data: Array,
});
const {data} = toRefs(props);
const selectYearRange = getSelectYearRange();

// props.experiences를 직접 수정하지 않고, localExperiences를 조작
const defaultItem = data.value?.[0] ? Object.fromEntries(Object.keys(data.value[0]).map(key => [key, ''])) : {};

const keyList = computed(() => Object.keys(defaultItem));

// 내부적으로 다룰 로컬 state
// props.experiences를 직접 수정하지 않고, localExperiences를 조작
const localData = reactive(props.data);

// 모든 타입에 대해 누락된 type이 있다면 초기 아이템 추가
ARTIST_EXPERIENCES.forEach(({value}) => {
  if (!localData.some((item) => item.type === value)) {
    localData.push({
      type: value,
      year: '',
      title: '',
      location: '',
      region: '',
    });
  }
});

function groupByType(dataArray) {
  return dataArray.reduce((acc, item) => {
    const group = acc[item.type] || [];
    group.push(item);
    acc[item.type] = group;
    return acc;
  }, {});
}

const groupedExperience = computed(() => groupByType(localData));

const addListItem = (type) => {
  const maxItems = 30 * ARTIST_EXPERIENCES.length;
  if (localData.length >= maxItems) {
    alert(`최대 ${maxItems}개까지 등록 가능합니다`);
    return;
  }

  localData.push({
    type,
    year: '',
    title: '',
    location: '',
    region: '',
  });
};

const deleteListItem = (type, index) => {
  // 1. type에 해당하는 item들만 찾기
  const itemsOfType = localData.filter((item) => item.type === type);

  // 2. 삭제할 아이템
  const itemToDelete = itemsOfType[index];
  if (itemToDelete) {
    // 3. 전체 localExperiences 배열에서 삭제
    const globalIndex = localData.indexOf(itemToDelete);
    if (globalIndex !== -1) {
      localData.splice(globalIndex, 1);
    }
  }
};

// 사용자가 최종 저장 시 가져갈 데이터
const getFilteredData = () => {
  return localData.filter(({year, title, location, region}) => {
    return year && title && location && region;
  });
};

const validateData = () => {
  let isAnyFieldFilled = false;

  for (const item of localData) {
    const {year, title, location, region} = item;
    if (year || title || location || region) {
      isAnyFieldFilled = true;
      if (!year || !title || !location || !region) {
        return {
          isValid: false,
          errorMessage:
              '입력 중인 경력이 있습니다. 모든 항목을 입력하거나 사용하지 않으면 삭제하세요.',
        };
      }
    }
  }

  if (!isAnyFieldFilled) {
    return {
      isValid: false,
      errorMessage: '경력이 입력되지 않았습니다. 최소 하나 이상의 경력을 입력해주세요.',
    };
  }

  return {
    isValid: true,
    errorMessage: null,
  };
};

// 외부에서 직접 참조 가능
defineExpose({
  getFilteredData,
  validateData,
});
</script>
<style scoped>
.add-list {
  position: absolute;
  right: -40px;
  top: 10px;
  width: 40px;
}

.add-list img {
  filter: invert(1);
}
</style>
