<template>
  <select v-model="selectedId">
    <option v-for="artistUserAccountId in artistUserAccountIds" :key="artistUserAccountId" :value="artistUserAccountId">
      {{ artistUserAccountId }}
    </option>
  </select>
  <a v-show="selectedId"
     :href="`https://article21.kr/artist/${selectedId}`"
     target="_blank">
    🔗 아티스트 페이지
  </a>
</template>

<script setup>
import {apiClient} from "@/services/auth-header";
import {onMounted, ref, computed, defineProps, defineEmits} from "vue";

const artistUserAccountIds = ref([]);
const props = defineProps({
  userAccountId: { type: String, default: '' }, // v-model로 받을 prop
});

const fetchArtistUserAccountIds = async () => {
  try {
    const response = await apiClient.get(`/users/artists/user-account-ids`);
    artistUserAccountIds.value = response.data;
  } catch (error) {
    console.error(error)
  }
};

const emit = defineEmits(['update:userAccountId']);

// computed를 사용하여 v-model과 자동 동기화
const selectedId = computed({
  get: () => props.userAccountId,
  set: (value) => emit('update:userAccountId', value),
});

onMounted(() => {
  fetchArtistUserAccountIds();
});

</script>

<style scoped>

</style>