import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useUiStore = defineStore('loading', () => {
  const isOverlay = ref(false)
  const isLoading = ref(false)

  const showOverlay = () => {
    isOverlay.value = true
    document.body.classList.add('on');
  }

  const hideOverlay = () => {
    isOverlay.value = false
    document.body.classList.remove('on');
  }

  const showLoading = () => {
    isLoading.value = true
  }

  const hideLoading = (time = 0) => {
    setTimeout(() => {
      isLoading.value = false;
    }, time);
  }

  return {
    isOverlay,
    isLoading,
    showOverlay,
    hideOverlay,
    showLoading,
    hideLoading,
  }
});
