<template>
  <br/>
  <tr>
    <td colspan="2">학력<em class="required">*</em>
      <hr/>
    </td>
  </tr>

  <tr>
    <td colspan="2">
      <div style="display: flex;">
        <div v-for="(item, index) in keyList" :key="index" style="width: 100%;">
          <input type="text" :value="item" disabled/>
        </div>
      </div>
    </td>
  </tr>


  <tr>
    <td colspan="2">
      <template v-for="(edu, index) in ARTIST_EDUCATIONS" :key="index">
        <div v-for="(education, indexExpItem) in groupedEducation[edu.value]" :key="indexExpItem">
          <div style="display: flex; position: relative;">
            <div v-for="([key, value], idx) in Object.entries(education)" :key="idx" style="width: 100%; margin-bottom: 4px;">
              <input v-if="key === 'type'" type="text" :placeholder="value" v-model="edu.label" disabled/>
              <CustomSelect v-else-if="key === 'year'" v-model="education[key]" :options="selectYearRange" placeholder="연도"/>
              <CustomSelect v-else-if="key === 'status'" v-model="education[key]" :options="ARTIST_EDUCATIONS_STATUS" placeholder="졸업 여부" />
              <input v-else type="text" :placeholder="value" v-model="education[key]"/>
            </div>
            <button v-if="indexExpItem === 0" type="button" class="add-list" @click="addListItem(education.type)">
              <img src="@/assets/images/icon/ico_add3.svg"/>
            </button>
            <button v-else type="button" class="add-list delete" @click="deleteListItem(education.type, indexExpItem)">
              <img src="@/assets/images/icon/ico_delete.svg"/>
            </button>
          </div>
        </div>
      </template>
    </td>
  </tr>

  <tr>
    <td colspan="2">
      <hr/>
    </td>
  </tr>
</template>

<script setup>
import {computed, reactive, toRefs} from 'vue';
import {ARTIST_EDUCATIONS, ARTIST_EDUCATIONS_STATUS} from "@/common/common-enum";
import {getSelectYearRange} from "@/common/common-function";
import CustomSelect from '@/components/ui/CustomSelect.vue';

const props = defineProps({
  data: Array,
});
const {data} = toRefs(props);
const selectYearRange = getSelectYearRange();

// props.experiences를 직접 수정하지 않고, localExperiences를 조작
const defaultItem = data.value?.[0] ? Object.fromEntries(Object.keys(data.value[0]).map(key => [key, ''])) : {};

const keyList = computed(() => Object.keys(defaultItem));

// 내부적으로 다룰 로컬 state
const localData = reactive(props.data);

// 모든 타입에 대해 누락된 type이 있다면 초기 아이템 추가
ARTIST_EDUCATIONS.forEach(({value}) => {
  if (!localData.some((item) => item.type === value)) {
    localData.push({
      type: value,
      year: '',
      schoolName: '',
      major: '',
      status: '',
    });
  }
});

function groupByType(dataArray) {
  return dataArray.reduce((acc, item) => {
    const group = acc[item.type] || [];
    group.push(item);
    acc[item.type] = group;
    return acc;
  }, {});
}

const groupedEducation = computed(() => groupByType(localData));

const addListItem = (type) => {
  const maxItems = 30 * ARTIST_EDUCATIONS.length;
  if (localData.length >= maxItems) {
    alert(`최대 ${maxItems}개까지 등록 가능합니다`);
    return;
  }

  localData.push({
    type,
    year: '',
    schoolName: '',
    major: '',
    status: '',
  });
};

const deleteListItem = (type, index) => {
  // 1. type에 해당하는 item들만 찾기
  const itemsOfType = localData.filter((item) => item.type === type);

  // 2. 삭제할 아이템
  const itemToDelete = itemsOfType[index];
  if (itemToDelete) {
    // 3. 전체 localExperiences 배열에서 삭제
    const globalIndex = localData.indexOf(itemToDelete);
    if (globalIndex !== -1) {
      localData.splice(globalIndex, 1);
    }
  }
};

// 사용자가 최종 저장 시 가져갈 데이터
const getFilteredData = () => {
  return localData.filter(({year, schoolName, major, status}) => {
    return year && schoolName && major && status;
  });
};

const validateData = () => {
  let isAnyFieldFilled = false; // 최소 하나의 항목이 입력되었는지 확인

  for (const item of localData) {
    const {year, schoolName, major, status} = item;
    if (year || schoolName || major || status) {
      isAnyFieldFilled = true;
      if (!year || !schoolName || !major || !status) {
        return {
          isValid: false,
          errorMessage:
              '입력 중인 학력이 있습니다. 저장하시려면 모든 항목을 입력해주세요.\n사용하지 않을 학력 정보라면 입력된 항목을 삭제해 주세요.',
        };
      }
    }
  }

  // 모든 값이 비어 있는 경우
  if (!isAnyFieldFilled) {
    return {
      isValid: false,
      errorMessage: "학력이 입력되지 않았습니다.\n대학, 대학원, 기타 중 하나 이상의 학력을 입력해주세요.",
    };
  }

  // Validation 성공
  return {
    isValid: true,
    errorMessage: null,
  };
};

defineExpose({
  getFilteredData,
  validateData
});

</script>
<style scoped>
.add-list {
  position: absolute;
  right: -40px;
  top: 10px;
  width: 40px;
}

.add-list img {
  filter: invert(1);
}
</style>
