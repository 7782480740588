<template>
    <div>
        <!-- 모달 팝업 -->
        <div v-show="isModalOpen" class="modal-crop">
            <div class="modal-crop-content">
                <h3>이미지 영역 선택</h3>


                <div class="spinner-wrapper">
                    <div v-show="spinner" class="btn-spinner"></div>
                </div>
                <!-- 이미지 크롭 영역 -->
                <div :style="{ visibility: spinner ? 'hidden' : 'visible' }">
                    <img id="cropper-image" :src="imageData.url" alt="Crop Image" />
                </div>

                <!-- 저장 / 닫기 버튼 -->
                <button @click="saveCroppedImage" type="button">저장</button>
                <button @click="closeCropModal" type="button">닫기</button>

            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, onUnmounted } from 'vue';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';

// 부모 컴포넌트에서 넘겨받은 props
const props = defineProps({
    imageFile: {  // imageData로 { url, name }을 받음
        type: Object,
        default: null,
    },
    maxWidth: {
        type: Number,
        default: 1,
    },
    maxHeight: {
        type: Number,
        default: 1,
    },
    isFixedSize: {  // 이미지 고정 크기 여부
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['cropped']);
const cropper = ref(null);
let croppedUrl = '';
const imageData = ref({ url: '', name: '' });
let isFixedSize = false;

const spinner = ref(true);
const isModalOpen = ref(false);

const initializeImage = () => {
    isModalOpen.value = true;
    spinner.value = true;
    isFixedSize = props.isFixedSize;
    imageData.value = { url: '', name: '' };
}

// 이미지 읽기 및 크기 확인
const loadImage = () => {
    return new Promise((resolve, reject) => {
        // 이미지 파일 확인
        if (!props.imageFile) {
            console.error("No image file provided");
            return reject("No image file provided");
        }

        const reader = new FileReader();
        reader.onload = () => {
            const image = new Image(); // 이미지 객체 생성
            image.onload = () => {
                // 크기 확인 및 로직 추가
                if (image.width < props.maxWidth || image.height < props.maxHeight) {
                    isFixedSize = true; // 필요한 경우 추가 로직
                }

                // 이미지 데이터 업데이트
                imageData.value = { url: reader.result, name: props.imageFile.name };

                resolve(); // 이미지 로드 완료 시 Promise 해결
            };

            image.onerror = () => reject("Failed to load image");
            image.src = reader.result; // 이미지 로드 시작
        };

        reader.onerror = () => reject("Failed to read file");
        reader.readAsDataURL(props.imageFile); // 파일 읽기 시작
    });
};

// 모달 닫기
const closeCropModal = () => {
    destroyCropper(); // 크롭퍼 객체 초기화
    isModalOpen.value = false;
};

// Cropper.js 초기화
const initializeCropper = () => {
    const imageElement = document.getElementById('cropper-image');
    if (!imageElement) {
        console.error("Image element not found");
        return;
    }

    // 기존 cropper 인스턴스가 있다면 파괴
    destroyCropper();

    // 새로운 cropper 인스턴스 생성
    cropper.value = new Cropper(imageElement, {
        aspectRatio: props.maxWidth / props.maxHeight,  // 계산된 비율을 사용
        viewMode: 1,
        // minCropBoxWidth: props.maxWidth,  // 최소 크롭 박스 너비
        // minCropBoxHeight: props.maxHeight,  // 최소 크롭 박스 높이
        ready() {
            spinner.value = false;
        },
    });
};

// 크롭퍼 객체 초기화 해제
const destroyCropper = () => {
    if (cropper.value) {
        cropper.value.destroy();
        cropper.value = null;
    }
};

// 크롭된 이미지 저장
const saveCroppedImage = () => {
    if (cropper.value) {
        // 크롭된 이미지를 Canvas로 변환
        const croppedCanvas = cropper.value.getCroppedCanvas({
            width: isFixedSize ? props.maxWidth : undefined,  // 고정된 크기 적용 여부
            height: isFixedSize ? props.maxHeight : undefined,  // 고정된 크기 적용 여부
        });

        croppedCanvas.toBlob((blob) => {
            const croppedFileName = `crop_${imageData.value.name}`;
            const croppedFile = new File([blob], croppedFileName, { type: blob.type });
            croppedUrl = URL.createObjectURL(blob);
            emit('cropped', { file: croppedFile, url: croppedUrl, name: croppedFileName });
            closeCropModal();
        }, props.imageFile.type);
    }
};

// 이미지 변경될 때마다 cropper 재초기화
watch(
    () => props.imageFile, // 특정 데이터만 감시
    async (newFile) => {
        if (newFile) {
            try {
                initializeImage();
                await loadImage(); // loadImage 완료될 때까지 기다림
                initializeCropper(); // loadImage 완료 후 실행
            } catch (error) {
                console.error("Error in loading image or initializing cropper:", error);
            }
        }
    }
);

onUnmounted(() => {
  destroyCropper();
  if (croppedUrl) {
    URL.revokeObjectURL(croppedUrl); // Blob URL 해제
    croppedUrl = '';
  }
});

</script>

<style scoped>
.modal-crop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    animation: fadeIn 0.3s ease-in-out;
}

.modal-crop-content img {
  max-width: 100%;
  max-height: 60vh;
  object-fit: contain;
}

/* 제목 스타일 */
.modal-crop-content h3 {
    text-align: center;
    font-weight: bold;
    margin-bottom: 30px;
    color: #333333;
    letter-spacing: 0.5px;
}

.modal-crop-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-height: 90%;
    max-width: 800px;
    position: relative;
    animation: slideUp 0.4s ease-out;
}

/* 부드러운 페이드 효과 */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* 슬라이드 업 효과 */
@keyframes slideUp {
    from {
        transform: translateY(50px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* 버튼 스타일 */
.modal-crop-content button {
    display: inline-block;
    padding: 12px 24px;
    font-weight: 500;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin: 10px 8px;
    transition: all 0.3s ease;
}

.modal-crop-content button:first-of-type {
    background-color: #F7BE17;
    color: #333333;
}

.modal-crop-content button:first-of-type:hover {
    background-color: #F7BE17;
    box-shadow: 0 4px 12px #d4a310;
}

.modal-crop-content button:last-of-type {
    background-color: #f8f9fa;
    color: #333333;
    border: 1px solid #ddd;
}

.modal-crop-content button:last-of-type:hover {
    background-color: #e2e6ea;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.modal-crop-content {
    text-align: center;
}
</style>
