<template>
  <div class="modal-wrap-4 modal" id="modalEditCategory">
    <div class="modal-inner">
      <button type="button" class="close-modal" @click="closeModal">
        <img src="@/assets/images/icon/ico_close_w.svg" alt="">
      </button>
      <div class="modal-content">
        <h4>카테고리 수정</h4>
        <div class="modal-form-box">
          <div class="input-wrap">
            <span>이름</span>
            <input type="text" v-model="inputCategoryName" placeholder="카테고리 이름을 설정해주세요." value="덩어리들">
          </div>
          <div class="input-wrap">
            <span class="checkbox-type3">
                <input type="checkbox" id="categoryHide02" v-model="inputCategoryHide" checked>
                <label for="categoryHide02">
                    <span>숨김</span>
                    <span class="sub">ART & ARTISTS에 노출하고 싶지 않을 경우 체크해주세요.</span>
                </label>
            </span>
          </div>
          <div class="input-wrap">
            <button type="button" id="btnDelCategory" @click="emit('openDeleteModal')">카테고리 삭제</button>
            <p>선택 시 카테고리가 ART & ARTISTS 페이지에서 삭제됩니다.
              <br>카테고리가 삭제돼도 아트워크는 유지됩니다.</p>
          </div>
        </div>
      </div>
      <div class="button-box">
        <button type="button" @click="updateCategory">완료</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, defineEmits, onMounted, onUnmounted } from 'vue';

const props = defineProps({
  categoryName: String,
  categoryHide: Boolean
});

const emit = defineEmits(['close', 'openDeleteModal', 'update:categoryName', 'update:categoryHide', 'update']);

// 🔹 getter/setter로 부모와 동기화
const inputCategoryName = computed({
  get: () => props.categoryName,
  set: (value) => emit('update:categoryName', value)
});

const inputCategoryHide = computed({
  get: () => props.categoryHide,
  set: (value) => emit('update:categoryHide', value)
});

const updateCategory = () => {
  window.history.back();
  emit('update');
}

const closeModal = () => {
  window.history.back(); // 히스토리 정리
};

const handlePopState = (event) => {

  const elementById = document.getElementById(`modalDelCategory`);
  if(elementById) return;

  emit('close'); // 뒤로가기 시 모달 닫기
};

onMounted(() => {
  window.history.pushState({ categoryUpdateModalOpen: true }, ''); // 히스토리 추가
  window.addEventListener('popstate', handlePopState);
});

onUnmounted(() => {
  window.removeEventListener('popstate', handlePopState);
});
</script>

<style scoped>

</style>