<template>
  <br/>
  <tr>
    <td colspan="2">{{ title }}
      <hr/>
    </td>
  </tr>

  <tr>
    <td colspan="2">
      <div v-for="(item, index) in internalData" :key="index" >
        <div style="display: flex; position: relative;">
          <input type="text" v-model="item.value" :placeholder="placeholder">
          <button v-if="index === 0" type="button" class="add-list" @click="addListItem()">
            <img src="@/assets/images/icon/ico_add3.svg">
          </button>
          <button v-else type="button" class="add-list delete" @click="removeItem(index)">
            <img src="@/assets/images/icon/ico_delete.svg">
          </button>
        </div>
      </div>
    </td>
  </tr>

  <tr>
    <td colspan="2">
      <hr/>
    </td>
  </tr>
</template>

<script setup>
import { reactive, toRefs } from 'vue'

const props = defineProps({
  title: String,
  placeholder: String,
  data: Array,
});
const { title, placeholder, data } = toRefs(props);

// props.data를 reactive 형태로 변환한 내부 데이터
const internalData = reactive(
  (props.data && props.data.length > 0) // props.data가 유효한 경우
    ? props.data.map((item) => ({
      value: item, // 기존 문자열을 객체 속성으로 변환
    }))
    : [{ value: '' }] // props.data가 없거나 빈 배열인 경우 기본값 추가
);

const maxItems = title.value === "인터뷰 링크" ? 3 : 30;
const addListItem = () => {
  if (internalData.length >= maxItems) {
    alert(`최대 ${maxItems}개까지 등록 가능합니다`);
    return;
  }
  internalData.push({ value: '' });
};
const removeItem = (index) => {
  internalData.splice(index, 1);

  // 데이터가 비어 있으면 기본값 추가
  if (internalData.length === 0) {
    internalData.push({ value: '' });
  }
};

const getData = () => {
  return Array.from(internalData)
    .filter(({ value }) => value) // 모든 필드가 채워진 항목만 필터링
    .map(({ value }) => value);  // value만 추출
};

defineExpose({
  getData
});
</script>

<style scoped>
.add-list {
  position: absolute;
  right: -40px;
  top: 10px;
  width: 40px;
}

.add-list img {
  filter: invert(1);    /* 흰색 아이콘을 검은색으로 반전 (배경색에 따라 다름) */
}

</style>