<template>
  <div class="contents">
    <div class="layout">
      <div class="white-panel mt30 pt30 pb30">
        <div class="search-panel">
          <table>
            <colgroup>
              <col style="width:150px;">
              <col>
            </colgroup>
            <tbody>
            <tr>
              <th>아이디</th>
              <td>
                <div class="input-search">
                  <div class="field">
                    <div class="input-box">
                      <input v-model="accountId" name="accountId" type="text" class="form-control"/>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>비밀번호</th>
              <td>
                <div class="input-search">
                  <div class="field">
                    <div class="input-box">
                        <input v-model="accountPwd" name="accountPwd" type="password" class="form-control"/>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="button-panel">
            <button @click="handleLogin" type="button" class="round-button navy">로그인</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useAuthStore} from "@/store/auth.module";
import {ref} from "vue";
import router from "@/router";

const authStore = useAuthStore();
const message = ref({});
const accountId = ref('');
const accountPwd = ref('');

const handleLogin = async () => {
  message.value = {}; // Clear previous error messages
  const user = {
    accountId: accountId.value,
    accountPwd: accountPwd.value
  }
  try {
    await authStore.login(user);
    router.push("/content/banner");
  } catch (error) {
    console.error(error)
  }
};
</script>
