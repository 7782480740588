<template>
  <div class="modal-wrap-3 modal del" id="modalDelCategory">
    <div class="modal-inner">
      <button type="button" class="close-modal" @click="closeModal">
        <img src="@/assets/images/icon/ico_close_w.svg" alt="">
      </button>
      <div class="modal-content">
        <h4>이 카테고리를 삭제할까요?</h4>
        <p>‘<span id="categoryName">{{ categoryName }}</span>’ 카테고리가 ART & ARTISTS 페이지에서 삭제됩니다.
          <br>카테고리가 삭제돼도 아트워크는 마이페이지에서 유지됩니다.
        </p>
      </div>
      <div class="button-box">
        <button type="button" @click="deleteCategory">삭제</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, defineProps, defineEmits } from 'vue';

defineProps({
  categoryName: String
});

const emit = defineEmits(['close', 'delete']);

const deleteCategory = () => {
  window.history.back();
  emit('delete');
  window.history.back(); // updatemodal 도 정리
}

const closeModal = () => {
  window.history.back();
};

const handlePopState = (event) => {
  emit('close'); // 뒤로가기 시 모달 닫기
};

onMounted(() => {
  window.history.pushState({ categoryDeleteModalOpen: true }, ''); // 뒤로가기 히스토리 추가
  window.addEventListener('popstate', handlePopState);
});

onUnmounted(() => {
  window.removeEventListener('popstate', handlePopState);
});
</script>

<style scoped>

</style>