<template>
  <div class="contents">
    <div class="layout">
      <!-- s::title-breadcrumbs -->
      <div class="page-top">
        <h2>이달의 아티스트 관리</h2>
        <div class="breadcrumbs">
          <span>컨텐츠 관리</span>
          <span class="color-black">이달의 아티스트 관리</span>
        </div>
      </div>
      <!-- e::title-breadcrumbs -->

      <div class="white-panel mt30">
        <div class="table-wrap">
          <table class="table-type2">
            <colgroup>
              <col style="width:150px;">
              <col>
            </colgroup>
            <tbody>
            <tr>
              <th>선정 년월<em class="required">*</em></th>
              <td>
                <select v-model="selectedYear" style="max-width: 200px;">
                  <option v-for="year in years" :key="year" :value="year">
                    {{ year }}년
                  </option>
                </select>
                <select v-model="selectedMonth" style="max-width: 200px;">
                  <option v-for="month in months" :key="month" :value="month">
                    {{ month }}월
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <th>순서<em class="required">*</em></th>
              <td>
                <input type="number" required v-model="item.sortOrder" max="100" maxlength="10 " style="max-width:540px;"/>
              </td>
            </tr>
            <tr>
              <th>ARTIST ID<em class="required">*</em></th>
              <td>
                <SelectArtistUserAccountId v-model:userAccountId="item.artistUserAccountId" />
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="button-panel mt30">
        <div class="left">
          <router-link :to="{ name: 'MonthlyArtist' }" class="button white-button round-button gray2">목록</router-link>
        </div>
        <div class="right">
          <button @click="saveItem" type="button" class="button navy-button">{{ monthlyArtistId ? '수정하기' : '등록하기' }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref, computed} from 'vue';
import {apiClient} from "@/services/auth-header";
import {useRoute} from "vue-router";
import router from "@/router";
import SelectArtistUserAccountId from "@/components/ui/SelectArtistUserAccountId.vue";

const route = useRoute();
const monthlyArtistId = ref(route.params.id || null);

const item = ref({
  id: null,
  artistUserAccountId: '',
  selectedMonth: '',
  sortOrder: 0,
})
const API_URL = "/contents";

// 현재 날짜 가져오기
const today = new Date();
const currentYear = today.getFullYear();
const currentMonth = today.getMonth() + 1; // getMonth()는 0부터 시작하므로 +1

// 다음 달 계산 (12월이면 다음 해 1월로 변경)
const nextMonth = currentMonth === 12 ? 1 : currentMonth + 1;
const nextMonthYear = currentMonth === 12 ? currentYear + 1 : currentYear;

// 연도 리스트 (현재 연도 기준 -1 ~ +1년)
const years = computed(() => {
  return Array.from({length: 3}, (_, i) => currentYear -1 + i);
});

// 1~12월 배열
const months = computed(() => Array.from({length: 12}, (_, i) => i + 1));

const selectedYear = ref(nextMonthYear);
const selectedMonth = ref(nextMonth);

// 최종적으로 YYYY.MM 형식으로 변환
const formattedSelectedMonth = computed(() => {
  return selectedYear.value && selectedMonth.value
      ? `${selectedYear.value}.${String(selectedMonth.value).padStart(2, "0")}`
      : "";
});

const fetchItem = async () => {
  try {
    const response = await apiClient.get(`${API_URL}/monthly-artists/${monthlyArtistId.value}`);
    item.value = response.data;
    const [year, month] = item.value.selectedMonth.split('.');
    selectedYear.value = parseInt(year, 10);
    selectedMonth.value = parseInt(month, 10);
  } catch (error) {
    console.error(error)
  }
};

const saveItem = async () => {
  item.value.selectedMonth = formattedSelectedMonth.value;
  if (!validateItem()) {
    return;
  }

  try {
    if (!monthlyArtistId.value) {
      // 등록
      await apiClient.post(API_URL + `/monthly-artists`, item.value);
    } else {
      // 수정
      await apiClient.put(API_URL + `/monthly-artists/${monthlyArtistId.value}`, item.value);
    }

    const resultMsg = monthlyArtistId.value ? "수정 되었습니다." : "등록 되었습니다.";
    alert(resultMsg);
    await router.push({ name: 'MonthlyArtist' });
  } catch (error) {
    console.error('Error uploading file:', error);
    alert(error.response.data);
  }

};

function validateItem() {
  if (!item.value.selectedMonth) {
    alert("선택된 월을 입력해주세요.");
    return false;
  }

  // selectedMonth가 올바른 형식 (YYYY.MM)인지 확인
  const monthPattern = /^(?:19|20)\d{2}\.(0[1-9]|1[0-2])$/; // 예시: 2025.03
  if (!monthPattern.test(item.value.selectedMonth)) {
    alert("선택된 월은 'YYYY.MM' 형식이어야 합니다.");
    return false;
  }

  if (item.value.sortOrder < 0) {
    alert("정렬 순서는 0 이상이여야 합니다.");
    return false;
  }

  if (!item.value.artistUserAccountId) {
    alert("Artist User Account ID를 입력해주세요.");
    return false;
  }

  return true;
}

onMounted(() => {
  if (monthlyArtistId.value) {
    fetchItem();
  }
});

</script>