<template>
  <div v-if="!loading" class="contents">
    <div class="layout">
      <UserListHeader title="아티스트 회원 관리" menu="아티스트 회원 관리" :selected="item.userAccountId"/>
      <div class="white-panel mt30">
        <div v-if="item" class="table-wrap">
          <table class="table-type2">
            <colgroup>
              <col style="width:150px;">
              <col>
            </colgroup>
            <tbody>
            <tr>
              <th>아이디<em class="required">*</em></th>
              <td>
                <a v-show="item.userAccountId" :href="`https://article21.kr/artist/${item.userAccountId}`"
                   target="_blank">{{ item.userAccountId }}🔗</a>
              </td>
            </tr>

            <tr>
              <th style="vertical-align: top;">프로필 이미지<em class="required">*</em></th>
              <td>
                <div class="file-list">
                  <ul>
                    <li>
                      <div class="attachment">
                        <div class="filebox2">
                          <label for="profile_file">
                            <span>{{ previewImageFile.url }}</span>
                          </label>
                          <input type="file" id="profile_file" @change="previewImage" accept="image/*"/>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <img v-if="previewImageFile.url" :src="previewImageFile.url" style="width: 200px; " alt=""/>
                </div>
              </td>
            </tr>

            <br/>
            <tr>
              <td colspan="2">이름<em class="required">*</em>
                <hr/>
              </td>
            </tr>
            <tr>
              <th>국문</th>
              <td>
                <input type="text" v-model="item.nameKo"/>
              </td>
            </tr>
            <tr>
              <th>영문</th>
              <td>
                <input type="text" v-model="item.nameEn"/>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <hr/>
              </td>
            </tr>

            <ProfileEducation ref="educationRef" :data="item.educations"/>
            <ProfileExperience ref="experienceRef" :data="item.experiences"/>
            <tr>
              <td colspan="2">활동 지역<em class="required">*</em>
                <hr/>
              </td>
            </tr>
            <tr>
              <th>국내</th>
              <td>
                <input type="text" v-model="item.domesticArea"/>
              </td>
            </tr>
            <tr>
              <th>해외</th>
              <td>
                <input type="text" v-model="item.overseasArea"/>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <hr/>
              </td>
            </tr>

            <tr>
              <th>활동 장르(중복 가능)</th>
              <td class="chk-group">
                <span v-for="genre in ARTIST_ACTIVITY_GENRES" :key="genre.value" class="checkbox-type3">
                  <input type="checkbox" :id="'chk1-' + genre.value" :value="genre.value"
                         v-model="item.activityGenreTypes">
                  <label :for="'chk1-' + genre.value">{{ genre.label }}</label>
                  <input type="text" placeholder="(직접 입력)"
                         v-model="item.activityGenreEtc"
                         v-if="genre.value === 'ETC' && item.activityGenreTypes.includes('ETC')">
                </span>
              </td>
            </tr>

            <ProfileObject ref="awardRef" title="수상 내역" placeholder="수상내역을 적어주세요." :data="item.awards"/>


            <ProfileCollection ref="collectionRef" title="작품 소장 내역" placeholder="작품이 소장된 기관 또는 장소가 있다면 적어주세요."
                               :data="item.collections"/>



            <ProfileObject ref="interviewRef" title="인터뷰 링크" placeholder="인터뷰 기사 링크가 있다면 넣어주세요."
                           :data="item.interviews"/>

            <tr>
              <th>아티스트 한 줄 소개</th>
              <td>
                <input type="text" v-model="item.introduction"/>
              </td>
            </tr>
            <tr>
              <th>동영상 링크</th>
              <td>
                <input type="text" v-model="item.videoLink" @input="validateVideoLink"
                       :style="{ color: isValidVideoLink ? 'inherit' : 'red' }"/>
              </td>
            </tr>
            <br/>
            <tr>
              <td colspan="2">article special link
                <hr/>
              </td>
            </tr>
            <tr>
              <th>special</th>
              <td>
                <select v-model="item.artistArticleSpecial.articleSpecialUuid">
                  <option v-for="articlesSpecial in articlesSpecials" :key="articlesSpecial.uuid" :value="articlesSpecial.uuid">
                    {{ articlesSpecial.title }}
                  </option>
                </select>
                <a v-show="item.artistArticleSpecial.articleSpecialUuid"
                   :href="`https://article21.kr/article/special/${item.artistArticleSpecial.articleSpecialUuid}`"
                   target="_blank">
                  🔗 ARTICLE SPECIAL 페이지
                </a>
              </td>
            </tr>
            <tr>
              <th>special message</th>
              <td>
                <input type="text" v-model="item.artistArticleSpecial.message"/>
              </td>
            </tr>
            <tr>
              <th style="vertical-align: top;">special image</th>
              <td>
                <div class="file-list">
                  <ul>
                    <li>
                      <div class="attachment">
                        <div class="filebox2">
                          <label for="special_file">
                            <span>{{ item.artistArticleSpecial.imageUrl }}</span>
                          </label>
                          <input type="file" id="special_file" @change="specialImage" accept="image/*"/>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <img v-if="item.artistArticleSpecial.imageUrl" :src="item.artistArticleSpecial.imageUrl" alt=""/>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <hr/>
              </td>
            </tr>


            </tbody>
          </table>
        </div>
      </div>

      <div class="button-panel mt30">
        <div class="left">
          <router-link :to="{ name: 'UserArtistList' }" class="button white-button round-button gray2">목록</router-link>
        </div>
        <div class="right">
          <button @click="saveItem" type="button" class="button navy-button">수정하기</button>
        </div>
      </div>
    </div>
    <ImageCropper :imageFile="imageFile" :maxWidth="maxWidth" :maxHeight="maxHeight" :isFixedSize="isFixedSize"
                  @cropped="handleCroppedImage"/>
  </div>
</template>

<script setup>
import UserListHeader from "./UserListHeader.vue";
import {onMounted, ref} from 'vue';
import {apiClient} from "@/services/auth-header";
import {useRoute} from "vue-router";
import {ARTIST_ACTIVITY_GENRES} from "@/common/common-enum";
import ProfileCollection from "@/components/profile/ProfileCollection.vue";
import ProfileObject from "@/components/profile/ProfileObject.vue";
import ProfileEducation from "@/components/profile/ProfileEducation.vue";
import ProfileExperience from "@/components/profile/ProfileExperience.vue";
import ImageCropper from "@/components/ui/ImageCropper.vue";

const route = useRoute();
const userAccountId = ref(route.params.userAccountId);

const loading = ref(true);
const item = ref({
  uuid: "",
  userAccountId: "",
  nameKo: "",
  nameEn: "",
  imageUrl: "",
  activityGenreTypes: "",
  activityGenreEtc: "",
  domesticArea: "",
  overseasArea: "",
  collections: [""],
  introduction: "",
  videoLink: "",
  isHidden: false,
  awards: [{
    "year": "",
    "title": ""
  }],
  interviews: [{
    "link": "",
    "title": ""
  }],
  educations: [{
    "type": "",
    "year": "",
    "schoolName": "",
    "major": "",
    "status": ""
  }],
  experiences: [{
    "type": "",
    "year": "",
    "title": "",
    "location": "",
    "region": ""
  }],
  artistArticleSpecial: {
    articleSpecialUuid: "",
    message: "",
    imageUrl: ""
  }
});

const articlesSpecials = ref([]);
const isValidVideoLink = ref(true);
const educationRef = ref(null);
const experienceRef = ref(null);
const awardRef = ref(null);
const collectionRef = ref(null);
const interviewRef = ref(null);
const previewImageFile = ref(null);
const selectedImageFile = ref(null);
const selectedSpecialImage = ref(null);

const specialImage = (event) => {
  selectedSpecialImage.value = event.target.files[0];
  item.value.artistArticleSpecial.imageUrl = URL.createObjectURL(event.target.files[0]);
}

const validateVideoLink = () => {
  try {
    const url = new URL(item.value.videoLink);
    isValidVideoLink.value = true; // 유효성 검사 로직 추가
  } catch (error) {
    isValidVideoLink.value = false;
  }
};

const validateMandatory = async () => {
  if (!previewImageFile.value) {
    alert('사진을 등록해 주세요.')
    return false;
  }

  if (!item.value.nameKo && !item.value.nameEn) {
    alert('작가명을 입력해 주세요.')
    return false;
  }

  if (!educationRef.value.validateData().isValid) {
    alert(educationRef.value.validateData().errorMessage);
    return false;
  }

  if (!experienceRef.value.validateData().isValid) {
    alert(experienceRef.value.validateData().errorMessage);
    return false;
  }

  if (!item.value.domesticArea && !item.value.overseasArea) {
    alert('활동지역을 입력해 주세요.');
    return false;
  }

  if (!item.value.activityGenreTypes || item.value.activityGenreTypes.length === 0) {
    alert('활동 장르를 선택해 주세요.');
    return false;
  }

  // if (!awardRef.value.validateData().isValid) {
  //   alert(awardRef.value.validateData().errorMessage);
  //   return false;
  // }

  return true;
}

const saveItem = async () => {
  const isValid = await validateMandatory();
  if (!isValid) {
    return;
  }

  if (confirm("저장 하시겠습니까?")) {
    const formData = new FormData();
    if (selectedImageFile.value) {
      formData.append('image', selectedImageFile.value);
    }

    if (selectedSpecialImage.value) {
      formData.append('specialImage', selectedSpecialImage.value);
    }

    item.value.educations = educationRef.value.getFilteredData();
    item.value.experiences = experienceRef.value.getFilteredData();
    item.value.awards = awardRef.value.getFilteredData();
    item.value.collections = collectionRef.value.getData();
    item.value.interviews = interviewRef.value.getFilteredData();
    formData.append('reqDto', new Blob([JSON.stringify(item.value)], {type: 'application/json'}));
    try {
      const response = await apiClient.put(`/users/artists/${userAccountId.value}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 200) {
        alert("수정 되었습니다.");
        location.reload();
      }
    } catch (error) {
      console.error('Error saveProfile data:', error);
    }
  }
};

const fetchItem = async () => {
  try {
    const response = await apiClient.get(`/users/artists/${userAccountId.value}`);
    Object.keys(response.data).forEach(key => {
      const value = response.data[key];
      if (value !== null && value !== '' && !(Array.isArray(value) && value.length === 0)) {
        item.value[key] = value;
      }
    });

    previewImageFile.value = {url: item.value.imageUrl};
    validateVideoLink();
    // console.log("item.value", item.value);
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
};

const fetchArticlesSpecials = async () => {
  try {
    const response = await apiClient.get(`/contents/articles/specials`);
    articlesSpecials.value = response.data;
  } catch (error) {
    console.error(error)
  }
};

// crop img
const imageFile = ref(null);
const maxWidth = 193;
const maxHeight = 270;
let isFixedSize = false; // 이미지 크기 고정 여부(고정 하면 maxWidth, maxHeight size 로 파일 생성, 안하면 비율만 유지)

const previewImage = async (event) => {
  const fileInput = event.target; // file input element
  const file = fileInput.files[0];
  if (!file) {
    fileInput.value = "";
    return;
  }
  const maxFileSize = 10 * 1024 * 1024; // 10MB
  if (file.size > maxFileSize) {
    alert("파일 크기가 너무 큽니다. 10MB 이하의 파일만 업로드 가능합니다.");
    return;  // 용량 초과시 함수 종료
  }

  // 부모에서 이미지 파일만 전달
  imageFile.value = file;
  fileInput.value = "";
};

const handleCroppedImage = async (newVal) => {
  const {file, url, name} = newVal;
  previewImageFile.value = {url, name};
  selectedImageFile.value = file;
};

onMounted(async () => {
  await fetchItem();
  await fetchArticlesSpecials();
});
</script>

<style scoped>

</style>