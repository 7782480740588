<template>
  <div class="contents">
    <div class="layout">

      <UserListHeader :title="userAccountId +'의 아트워크 관리'" menu="아티스트 관리" :selected="userAccountId +'의 아트워크 관리'"/>

      <div class="white-panel mt30 pt30 pb30">
        <div class="search-panel">
          <table>
            <colgroup>
              <col style="width:150px;">
              <col>
              <col>
            </colgroup>
            <tbody>
            <tr>
              <th>카테고리</th>
              <td>
                <select :value="selectCategoryUuid" @input="handleSelectedArtWork">
                  <option value="">ALL</option>
                  <option v-for="category in artWorksCategories" :key="category.uuid" :value="category.uuid" :class="getCategoryClass(category.uuid)">
                    {{ category.isHidden ? '[숨김]' : '' }} {{ category.name }}
                  </option>
                </select>
              </td>
              <td>
                <div class="control-group ml10">
                  <div class="btn-group">
                    <button v-if="selectCategoryUuid" @click="setCategoryUpdateModalVisible(true)" type="button" class="blue-button modify-button">
                      카테고리 수정
                    </button>
                    <button @click="setCategoryAddModalVisible(true)" type="button" class="navy-button add-button">카테고리 추가</button>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="list-top mt50">
        <div class="control-group">
          <div class="btn-group">
            <button @click="openModal(MODE_REGISTER, null)" type="button" class="navy-button add-button">아트워크 등록</button>
          </div>
        </div>
        <div class="control-group">
          <div class="btn-group">
            <button @click="updateArtWorksCategoryAndOrder" type="button" class="navy-button modify-button">카테고리 및 순서 설정 완료</button>
          </div>
        </div>
      </div>

      <div class="white-panel mt20">
        <div class="table-wrap">
          <div class="scroll-box">
            <table class="table-type1">
              <!-- 컬럼 비율 설정 -->
              <colgroup>
                <col style="width: 10%;">
                <col style="width: 25%;">
                <col style="width: 25%;">
                <col style="width: 30%;">
                <col style="width: 10%;">
              </colgroup>
              <thead>
              <tr>
                <th>이미지</th>
                <th>제목(국문)</th>
                <th>제목(영문)</th>
                <th>카테고리</th>
                <th>{{ selectCategoryUuid && categoryName(selectCategoryUuid) }} 순서</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="artWork in filteredArtWorks" :key="artWork.uuid">
                <td @click="openModal(MODE_MODIFY, artWork)" style="cursor: pointer">
                  <img :src="artWork.imageUrl" :alt="artWork.title" class="img"/>
                </td>
                <td @click="openModal(MODE_MODIFY, artWork)" style="cursor: pointer">
                  {{ artWork.title }}
                </td>
                <td @click="openModal(MODE_MODIFY, artWork)" style="cursor: pointer">
                  {{ artWork.titleEn }}
                </td>
                <td>
                  <select v-model="artWork.categoryUuid" @change="changeCategory(artWork)" style="width: 100%"
                          :class="getCategoryClass(artWork.categoryUuid)">
                    <option value=null>ALL</option>
                    <option v-for="category in artWorksCategories" :key="category.uuid" :value="category.uuid"
                            :class="getCategoryClass(category.uuid)">
                      {{ category.name }}
                    </option>
                  </select>
                </td>

                <td v-if="!selectCategoryUuid">
                  <select v-model="artWork.sortOrder" @change="changeSortOrder(artWork)" style="width: 100%">
                    <!-- 셀렉트 박스의 옵션을 순서대로 표시 -->
                    <option v-for="n in filteredArtWorks.length" :key="n-1" :value="n-1">{{ n - 1 }}</option>
                  </select>
                </td>
                <td v-else>
                  <select v-model="artWork.categorySortOrder" @change="changeCategorySortOrder(artWork)">
                    <!-- 셀렉트 박스의 옵션을 순서대로 표시 -->
                    <option v-for="n in filteredArtWorks.length" :key="n-1" :value="n-1">{{ n - 1 }}</option>
                  </select>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- 아트워크 등록 모달 -->
    <ArtworkModal
        v-if="isModalVisible"
        :art-work="modalArtWork"
        :modal-mode="modalMode"
        :api-url="API_URL"
        @close="isModalVisible = false"
    />


    <!-- 카테고리 추가 모달 -->
    <CategoryAddModal
        v-if="isCategoryAddModalVisible"
        v-model:categoryName="inputCategoryName"
        v-model:categoryHide="inputCategoryHide"
        @save="saveCategory"
        @close="setCategoryAddModalVisible(false)"
    />

    <!-- 카테고리 수정 모달 -->
    <CategoryUpdateModal
        v-if="isCategoryUpdateModalVisible"
        v-model:categoryName="inputCategoryName"
        v-model:categoryHide="inputCategoryHide"
        @close="setCategoryUpdateModalVisible(false)"
        @openDeleteModal="setCategoryDeleteModalVisible(true)"
        @update="updateCategory"
    />

    <!-- 카테고리 삭제 모달 -->
    <CategoryDeleteModal
        v-if="isCategoryDeleteModalVisible"
        :categoryName="categoryName(selectCategoryUuid)"
        @close="setCategoryDeleteModalVisible(false)"
        @delete="deleteCategory"
    />


  </div>
</template>

<script setup>
import UserListHeader from "./UserListHeader.vue";
import {onMounted, ref, computed} from 'vue';
import {useRoute} from "vue-router";
import {apiClient} from "@/services/auth-header";
import CategoryAddModal from "@/components/profileArtWork/CategoryAddModal.vue";
import CategoryUpdateModal from "@/components/profileArtWork/CategoryUpdateModal.vue";
import CategoryDeleteModal from "@/components/profileArtWork/CategoryDeleteModal.vue";
import ArtworkModal from "@/components/profileArtWork/ArtworkModal.vue";

const route = useRoute();
const userAccountId = ref(route.params.userAccountId);
const API_URL = `/artists/${userAccountId.value}/artworks`;

const allArtWorks = ref([]);
const artWorksCategories = ref(null);
const selectCategoryUuid = ref(null);

const handleSelectedArtWork = (event) => {
  selectCategoryUuid.value = event.target.value;
};

const categoryName = computed(() => (targetUuid) =>
    findCategoryNameByUuid(targetUuid)
);

const findCategoryNameByUuid = (targetUuid) => {
  const category = artWorksCategories.value.find(category => category.uuid === targetUuid);
  return category ? category.name : null;
};

const filteredArtWorks = computed(() => {
  return allArtWorks.value
  .filter((artWork) => !selectCategoryUuid.value || artWork.categoryUuid === selectCategoryUuid.value)
  .sort((a, b) => {
    if (selectCategoryUuid.value) {
      return a.categorySortOrder - b.categorySortOrder;
    }
    return 0; // 기본 순서 유지
  });
});

const findFilteredArtWorksByCategoryUuid = (selectCategoryUuid) => {
  return allArtWorks.value
  .filter((artWork) => !selectCategoryUuid || artWork.categoryUuid === selectCategoryUuid)
  .sort((a, b) => {
    if (selectCategoryUuid) {
      return a.categorySortOrder - b.categorySortOrder;
    }
    return 0; // 기본 순서 유지
  });
};

/* 아트워크 모달 s */
const isModalVisible = ref(false);
const modalArtWork = ref(null);
const modalMode = ref(null);
const MODE_REGISTER = 'register';
const MODE_MODIFY = 'modify';

const openModal = (mode, artWork) => {
  if (artWork) {
    modalArtWork.value = artWork;
  } else {
    modalArtWork.value = {
      uuid: null,
      imageUrl: null,
      title: null,
      titleEn: null,
      year: '',
      size: null,
      material: null,
      genre: null,
      etc: null,
      themeSubject: '',
      themeTime: '',
      themeFocus: '',
      themeStyle: '',
      description: null,
    };
  }

  modalMode.value = mode;
  isModalVisible.value = true;
}

// 아트워크 삭제시 백단에서 다시 정렬함. (플랫폼/어드민 공통으로 변경)
const deleteArtWorkSetOrder = (deleteArtWork) => {
  const originalIndex = deleteArtWork.sortOrder;
  const categoryUuid = deleteArtWork.categoryUuid;

  allArtWorks.value.splice(originalIndex, 1);
  allArtWorks.value.forEach((artWork, index) => {
    artWork.sortOrder = index;
  });

  const filteredArtWorks = findFilteredArtWorksByCategoryUuid(categoryUuid);
  filteredArtWorks.forEach((artWork, index) => {
    artWork.categorySortOrder = index;
  });

  updateArtWorksCategoryAndOrder();
}
/* 아트워크 모달 e */


/* 카테고리 모달 s */
const isCategoryAddModalVisible = ref(false);
const isCategoryUpdateModalVisible = ref(false);
const isCategoryDeleteModalVisible = ref(false);

const inputCategoryName = ref(null);
const inputCategoryHide = ref(false);

const saveCategory = async () => {
  try {
    if (!inputCategoryName.value) {
      alert("카테고리 이름을 입력해주세요.");
      return;
    }

    if (artWorksCategories.value.length >= 3) {
      alert("생성 가능한 카테고리 갯수를 초과했습니다. (3개까지 생성 가능)");
      return;
    }

    await apiClient.post(`${API_URL}/categories`, {
      name: inputCategoryName.value,
      isHidden: inputCategoryHide.value,
    });

    await fetchArtWorksCategories();

    setCategoryAddModalVisible(false);
    alert("카테고리가 생성되었습니다.");
  } catch (error) {
    alert("카테고리 생성에 실패했습니다.");
  }
};

const updateCategory = async () => {
  try {
    if (!inputCategoryName.value) {
      alert("카테고리 이름을 입력해주세요.");
      return;
    }

    await apiClient.put(`${API_URL}/categories/${selectCategoryUuid.value}`, {
      name: inputCategoryName.value,
      isHidden: inputCategoryHide.value,
    });

    alert("카테고리가 수정되었습니다.");
    location.reload();
  } catch (error) {
    alert("카테고리 수정에 실패했습니다.");
  }
}

const deleteCategory = async () => {
  try {
    await apiClient.delete(`${API_URL}/categories/${selectCategoryUuid.value}`, {
      name: inputCategoryName.value,
      isHidden: inputCategoryHide.value,
    });

    alert("카테고리가 삭제되었습니다.");
    location.reload();
  } catch (error) {
    alert("카테고리 삭제에 실패했습니다.");
  }
}

const setCategoryAddModalVisible = (isVisible) => {
  inputCategoryName.value = null;
  inputCategoryHide.value = false;

  isCategoryAddModalVisible.value = isVisible
}

const setCategoryUpdateModalVisible = (isVisible) => {
  if (isVisible) {
    const category = artWorksCategories.value.find(category => category.uuid === selectCategoryUuid.value);
    inputCategoryName.value = category.name;
    inputCategoryHide.value = category.isHidden;
  } else {
    inputCategoryName.value = null;
    inputCategoryHide.value = false;
  }

  isCategoryUpdateModalVisible.value = isVisible
}

const setCategoryDeleteModalVisible = (isVisible) => {
  isCategoryDeleteModalVisible.value = isVisible
}
/* 카테고리 모달 e */

/* 카테고리 & 순서 변경 s */
const getCategoryClass = (categoryUuid) => {
  const selectedIndex = artWorksCategories.value.findIndex(category => category.uuid === categoryUuid);
  if (selectedIndex === 0) {
    return 'category-1';  // 첫 번째 카테고리일 경우
  } else if (selectedIndex === 1) {
    return 'category-2';  // 두 번째 카테고리일 경우
  } else if (selectedIndex === 2) {
    return 'category-3';  // 세 번째 카테고리일 경우
  } else {
    return '';  // 그 외의 카테고리들은 기본 스타일
  }
};

const changeCategory = (updatedArtWork) => {
  const originalArtWork = allArtWorks.value.find(
      (item) => item.uuid === updatedArtWork.uuid
  );

  if (!originalArtWork) {
    return;
  }

  originalArtWork.categoryUuid = updatedArtWork.categoryUuid !== "null" ? updatedArtWork.categoryUuid : null;
  originalArtWork.categorySortOrder = null;

  if (updatedArtWork.categoryUuid) {
    const count = allArtWorks.value.filter(
        (artWork) => artWork.categoryUuid === updatedArtWork.categoryUuid
    ).length;
    originalArtWork.categorySortOrder = count - 1;
  }

  artWorksCategories.value.forEach(category => {
    const filteredArtWorks = findFilteredArtWorksByCategoryUuid(category.uuid);
    filteredArtWorks.forEach((artWork, index) => {
      artWork.categorySortOrder = index;
    });
  })

};

// sortOrder 변경 시 호출되는 함수
const changeSortOrder = (updatedArtWork) => {
  const updatedIndex = updatedArtWork.sortOrder;
  const originalIndex = allArtWorks.value.findIndex(
      (artWork) => artWork.uuid === updatedArtWork.uuid
  );

  if (originalIndex === -1 || updatedIndex === originalIndex) {
    return;
  } // 원래 위치와 동일하면 리턴

  // 1. originalIndex에서 항목을 삭제
  const [movedItem] = allArtWorks.value.splice(originalIndex, 1);

  // 2. updatedIndex에 항목을 삽입
  allArtWorks.value.splice(updatedIndex, 0, movedItem);

  allArtWorks.value.forEach((artWork, index) => {
    artWork.sortOrder = index;
  });
};

const changeCategorySortOrder = (updatedArtWork) => {
  const updatedIndex = updatedArtWork.categorySortOrder;

  // 현재 변경된 항목의 원래 위치 찾기
  const originalIndex = filteredArtWorks.value.findIndex(
      (artWork) => artWork.uuid === updatedArtWork.uuid
  );

  // 1. 기존 위치에서 항목 제거
  const [movedItem] = filteredArtWorks.value.splice(originalIndex, 1);

  // 2. 새로운 위치에 삽입
  filteredArtWorks.value.splice(updatedIndex, 0, movedItem);

  // 3. 전체 순서 업데이트
  filteredArtWorks.value.forEach((artWork, index) => {
    // artWork.categorySortOrder = index;
    const originalArtWork = allArtWorks.value.find(
        (item) => item.uuid === artWork.uuid
    );
    if (originalArtWork) {
      originalArtWork.categorySortOrder = index;
    }
  });
};

const updateArtWorksCategoryAndOrder = async () => {
  const payload = allArtWorks.value.map((artWork) => ({
    artWorkUuid: artWork.uuid,
    // "ALL"인 경우엔 sortOrder 업데이트, 그 외엔 categorySortOrder 업데이트
    sortOrder: artWork.sortOrder != null ? artWork.sortOrder : null,
    categoryUuid: artWork.categoryUuid || null,
    categorySortOrder: artWork.categorySortOrder != null ? artWork.categorySortOrder : null,
  }));

  try {
    await apiClient.put(`${API_URL}/category-and-order-bulk`, payload);
    alert("정상 반영되었습니다.");
    // await fetchArtWorks();
    location.reload();
  } catch (error) {
    console.error("업데이트 실패:", error);
    // alert("업데이트에 실패했습니다.");
  }
};
/* 카테고리 & 순서 변경 e */

/* 아트워크 조회 s */
const fetchArtWorks = async () => {

  try {
    const response = await apiClient.get(`${API_URL}`);
    allArtWorks.value = response.data;
  } catch (error) {
    console.error('Error:', error);
  }
};

const fetchArtWorksCategories = async () => {
  try {
    const response = await apiClient.get(`${API_URL}/categories`);
    artWorksCategories.value = response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}
/* 아트워크 조회 e */


onMounted(async () => {
  await fetchArtWorksCategories();
  await fetchArtWorks();
});
</script>

<style scoped>

.img {
  aspect-ratio: 1 / 1;
  width: 100%;
  object-fit: contain;
}

.category-1 {
  background-color: rgba(230, 167, 247, 0.3); /* 부드러운 연한 빨강 (투명도 30%) */
}

.category-2 {
  background-color: rgba(255, 235, 153, 0.3); /* 부드러운 연한 노랑 (투명도 30%) */
}

.category-3 {
  background-color: rgba(204, 231, 255, 0.3); /* 부드러운 연한 파랑 (투명도 30%) */
}


</style>