<template>
  <div class="modal-wrap-4 modal" id="modalAddCategory" >
    <div class="modal-inner">
      <button type="button" class="close-modal" @click="closeModal">
        <img src="@/assets/images/icon/ico_close_w.svg" alt="">
      </button>
      <div class="modal-content">
        <h4>카테고리 만들기</h4>
        <div class="modal-form-box">
          <div class="input-wrap">
            <span>이름</span>
            <input type="text" v-model="inputCategoryName" id="categoryInput" placeholder="카테고리 이름을 설정해주세요." required>
          </div>
          <div class="input-wrap">
            <span class="checkbox-type3">
              <input type="checkbox" id="categoryHide" v-model="inputCategoryHide">
              <label for="categoryHide">
                <span>숨김</span>
                <span class="sub">ART & ARTISTS에 노출하고 싶지 않을 경우 체크해주세요.</span>
              </label>
            </span>
          </div>
        </div>
      </div>
      <div class="button-box">
        <button type="button" id="categorySave" @click="saveCategory">만들기</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, defineEmits, onMounted, onUnmounted } from 'vue';

const props = defineProps({
  categoryName: String,
  categoryHide: Boolean
});

const emit = defineEmits(['close', 'update:categoryName', 'update:categoryHide', 'save']);

// 🔹 getter/setter로 부모와 동기화
const inputCategoryName = computed({
  get: () => props.categoryName,
  set: (value) => emit('update:categoryName', value)
});

const inputCategoryHide = computed({
  get: () => props.categoryHide,
  set: (value) => emit('update:categoryHide', value)
});

const saveCategory = () => {
  window.history.back();
  emit('save');
}

const closeModal = () => {
  window.history.back(); // 히스토리 정리
};

const handlePopState = (event) => {
  emit('close'); // 뒤로가기 시 모달 닫기
};

onMounted(() => {
  window.history.pushState({ categoryAddModalOpen: true }, ''); // 히스토리 추가
  window.addEventListener('popstate', handlePopState);
});

onUnmounted(() => {
  window.removeEventListener('popstate', handlePopState);
});
</script>

<style scoped>

</style>