<template>
  <transition name="fade">
    <div v-show="isOverlay" class="overlay"></div>
  </transition>
  <transition name="fade">
    <div v-show="isLoading" class="spinner-wrap">
      <div class="loading-box">
<!--        <i><img src="@/assets/images/common/ARTICLE21_B.I.svg" alt=""/></i>-->
        <span class="main-spinner"></span>
      </div>
    </div>
  </transition>

</template>

<script setup>
import {storeToRefs} from 'pinia'
import {useUiStore} from "@/store/useUiStore";

const uiStore = useUiStore();
const {isOverlay, isLoading} = storeToRefs(uiStore)
</script>

<style scoped>

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}


/* 임시 스피너 */
.main-spinner {
  width: 40px;
  height: 40px;
  border: 6px solid #F7BE17;
  border-top: 6px solid #ffffff00;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>