<template>
  <div class="contents">
    <div class="layout">

      <UserListHeader title="일반 회원 관리" menu="일반 회원 관리"/>

      <div class="white-panel mt30 pt30 pb30">
        <div class="search-panel">
          <table>
            <colgroup>
              <col style="width:150px;">
              <col>
            </colgroup>
            <tbody>
            <tr>
              <th>검색어</th>
              <td>
                <div class="input-search">
                  <input v-model="searchQuery" @keyup.enter="fetchItems" type="text" name="search-input"
                         placeholder="검색어 입력">
                  <i @click="fetchItems" class="button-search" style="cursor: pointer;"><img
                      src="@/assets/images/ico/ico_search.svg" alt=""/></i>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="white-panel mt20">
        <div class="table-wrap">
          <div class="scroll-box">
            <table class="table-type1">
              <!-- 컬럼 비율 설정 -->
              <colgroup>
                <col style="width: 10%;"> <!-- NO -->
                <col style="width: 10%;"> <!-- 이름 -->
                <col style="width: 20%;"> <!-- ID -->
                <col style="width: 20%;"> <!-- 이메일 -->
                <col style="width: 20%;"> <!-- 전화번호 -->
                <col style="width: 20%;"> <!-- 가입일시 -->
              </colgroup>
              <thead>
              <tr>
                <th>NO</th>
                <th>이름</th>
                <th>ID</th>
                <th>이메일</th>
                <th>전화번호</th>
                <th>가입일시</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <td>{{ index + 1 }}</td>
                <td>
                  <router-link :to="{ name: 'UserMemberDetail', params: { userUuid: item.uuid } }">
                    {{ item.name }}
                  </router-link>
                </td>
                <td>{{ item.accountId }}</td>
                <td>{{ item.email }}</td>
                <td>
                  {{ item.phoneNum }}
                </td>
                <td>{{ item.createdAt }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import UserListHeader from "./UserListHeader.vue";
import {onMounted, ref} from 'vue';
import {apiClient} from "@/services/auth-header";
import UserMemberDetail from "@/views/user/UserMemberDetail.vue";

const items = ref([]);
const searchQuery = ref('');

const fetchItems = async () => {
  try {
    const response = await apiClient.get(`/users?roleTypes=MEMBER`);
    items.value = response.data;
  } catch (error) {
    console.error('Error:', error);
  }
};

const search = () => {
  fetchItems();
};

onMounted(async () => {
  await fetchItems();
});
</script>