<template>
  <br/>
  <tr>
    <td colspan="2">{{ title }}
      <hr/>
    </td>
  </tr>

  <tr>
    <td colspan="2">
      <div style="display: flex;">
        <div v-for="(item, index) in keyList" :key="index" style="width: 100%;">
          <input type="text" :value="item" disabled/>
        </div>
      </div>
    </td>
  </tr>

  <tr>
    <td colspan="2">
      <div v-for="(item, index) in localData" :key="index">
        <div style="display: flex; position: relative;">
          <div v-for="([key, value], idx) in Object.entries(item)" :key="idx" style="width: 100%; margin-bottom: 4px;">
            <CustomSelect v-if="key === 'year'" v-model="item[key]" :options="selectYearRange" placeholder="연도" />
            <input v-else type="text" :placeholder="value" v-model="item[key]"/>
          </div>
          <button v-if="index === 0" type="button" class="add-list" @click="addListItem()">
            <img src="@/assets/images/icon/ico_add3.svg"/>
          </button>
          <button v-else type="button" class="add-list delete" @click="removeItem(index)">
            <img src="@/assets/images/icon/ico_delete.svg"/>
          </button>
        </div>
      </div>
    </td>
  </tr>

  <tr>
    <td colspan="2">
      <hr/>
    </td>
  </tr>
</template>

<script setup>
import {reactive, toRefs, computed} from 'vue';
import CustomSelect from '@/components/ui/CustomSelect.vue';
import { getSelectYearRange } from "@/common/common-function";

const selectYearRange = getSelectYearRange();
const props = defineProps({
  title: String,
  placeholder: String,
  data: Array,
});

const {title, data} = toRefs(props);

// **초기 데이터 동적 생성**: 첫 번째 항목의 구조를 기반으로 동적 처리
const defaultItem = data.value?.[0] ? Object.fromEntries(Object.keys(data.value[0]).map(key => [key, ''])) : {};

const keyList = computed(() => Object.keys(defaultItem));

const localData = reactive(
    data.value?.length > 0
        ? data.value.map(item => ({...item}))
        : [defaultItem]
);

const addListItem = () => {
  const maxItems = 30;
  if (localData.length >= maxItems) {
    alert(`최대 ${maxItems}개까지 등록 가능합니다`);
    return;
  }
  localData.push({...defaultItem});
};

const removeItem = (index) => {
  localData.splice(index, 1);
  if (localData.length === 0) {
    localData.push({...defaultItem});
  }
};

const getFilteredData = () => {
  return localData.filter(item =>
      Object.values(item).every(value => value !== '')
  );
};

const validateData = () => {
  let isAnyFieldFilled = false;
  for (const item of localData) {
    const hasValue = Object.values(item).some(value => value !== '');
    if (hasValue) {
      isAnyFieldFilled = true;
      if (Object.values(item).some(value => !value)) {
        return {
          isValid: false,
          errorMessage: '모든 필드를 입력하거나 불필요한 항목을 삭제해 주세요.',
        };
      }
    }
  }

  return {isValid: true, errorMessage: null};
};

defineExpose({
  getFilteredData,
  validateData
});
</script>

<style scoped>
.add-list {
  position: absolute;
  right: -40px;
  top: 10px;
  width: 40px;
}

.add-list img {
  filter: invert(1);
}
</style>
