<template>
  <div class="modal-wrap-3 modal" id="modalDelArtwork">
    <div class="modal-inner">
      <button type="button" class="close-modal" @click="closeModal">
        <img src="@/assets/images/icon/ico_close_w.svg" alt="">
      </button>
      <div class="modal-content">
        <h4>아트워크를 삭제할까요?</h4>
        <p>선택하신 아트워크가 ART & ARTISTS 페이지에서 삭제됩니다.</p>
      </div>
      <div class="button-box">
        <button type="button" @click="deleteArtwork">삭제</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineEmits, onMounted, onUnmounted} from 'vue';

const emit = defineEmits(['close', 'delete']);

const deleteArtwork = () => {
  window.history.back();
  emit('delete');
};

const closeModal = () => {
  window.history.back();
};

const handlePopState = (event) => {
  emit('close');
};

onMounted(() => {
  window.history.pushState({ artWorkDeleteModalOpen: true }, '');
  window.addEventListener('popstate', handlePopState);
});

onUnmounted(() => {
  window.removeEventListener('popstate', handlePopState);
});

</script>


<style scoped>

</style>